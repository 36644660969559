<template>
  <div id="writeArticles" class="vx-row" style="padding-left: 20px">
    <div class="vx-col w-full md:w-2/3" style="min-width: 630px;">
      <div class="flex flex-wrap items-center flex-grow">
        <feather-icon class="sm:inline-flex xl:hidden cursor-pointer" icon="MenuIcon" style="height: 20px; margin-right: 10px;" @click.stop="showSidebar"></feather-icon>
        <ul class="flex flex-wrap items-center">
          <li>
            <vs-button v-if="delFlg" color="danger" size="small" type="filled" v-on:click="deleteArticle">삭제</vs-button> 
          </li>
          <li>
            <md-radio @change="photoCheck('off')" v-model="sendData.type" :value="'1'">일반기사</md-radio>
          </li>
          <li>
            <md-radio @change="photoCheck('on')" v-model="sendData.type" :value="'9'">포토기사</md-radio>
          </li>
        </ul>
      </div>
      <div id="toolbar2" slot="toolbar">
        <span style="float: left; font-size: 19px;"><b>홈페이지 제목 |  </b></span>
        <button class="ql-italic">Italic</button>
        <button class="ql-underline">Underline</button>
        <span class="ql-color">
          <select class="ql-color" title="Colour"></select>
        </span>
        <button id="custom-button-2-4" style="width: 50px" @click="customButtonClick($refs.quillEditor_title.quill, sendData.byline)">매크로</button>
        <select class="ql-customSpecial" style="line-height: 1.8em;">
          <option value="※"/>
          <option value="▲"/>
          <option value="△"/>
          <option value="ⓒ"/>
          <option value="→"/>
          <option value="…"/>
          <option value="·"/>
          <option value="㈜"/>

          <option value="￠"/>
          <option value="￡"/>
          <option value="￥"/>
          <option value="￦"/>
          <option value="₠"/>
          <option value="₡"/>
          <option value="₢"/>
          <option value="₣"/>

          <option value="☚"/>
          <option value="☛"/>
          <option value="☜"/>
          <option value="☝"/>
          <option value="☞"/>
          <option value="☟"/>
          <option value="☢"/>
          <option value="♺"/>

          <option value="♩"/>
          <option value="♪"/>
          <option value="♫"/>
          <option value="♬"/>
          <option value="♭"/>
          <option value="♮"/>
          <option value="♯"/>
          <option value="♰"/>
          
          <option value="☼"/>
          <option value="☀"/>
          <option value="☽"/>
          <option value="☾"/>
          <option value="☁"/>
          <option value="☂"/>
          <option value="☃"/>
          <option value="☄"/>

          <option value="↑"/>
          <option value="↓"/>
        </select>
      </div>
      <local-quill-editor v-model="sendData.mainTitle"
                    ref="quillEditor_title"
                    :options="editorOption2"
                    @change="onEditorChange($event)">
      </local-quill-editor>
      
      <div id="toolbar3" slot="toolbar" v-if="sendData.type != '9'">
        <span style="float: left; font-size: 19px;"><b>부 제 |  </b></span>
        <button class="ql-italic">Italic</button>
        <button class="ql-underline">Underline</button>
        <span class="ql-color">
          <select class="ql-color" title="Colour"></select>
        </span>
        <button id="custom-button-3-4" style="width: 50px" @click="customButtonClick($refs.quillEditor_sub_title.quill, sendData.byline)">매크로</button>
        <select class="ql-customSpecial" style="line-height: 1.8em;">
          <option value="※"/>
          <option value="▲"/>
          <option value="△"/>
          <option value="ⓒ"/>
          <option value="→"/>
          <option value="…"/>
          <option value="·"/>
          <option value="㈜"/>

          <option value="￠"/>
          <option value="￡"/>
          <option value="￥"/>
          <option value="￦"/>
          <option value="₠"/>
          <option value="₡"/>
          <option value="₢"/>
          <option value="₣"/>

          <option value="☚"/>
          <option value="☛"/>
          <option value="☜"/>
          <option value="☝"/>
          <option value="☞"/>
          <option value="☟"/>
          <option value="☢"/>
          <option value="♺"/>

          <option value="♩"/>
          <option value="♪"/>
          <option value="♫"/>
          <option value="♬"/>
          <option value="♭"/>
          <option value="♮"/>
          <option value="♯"/>
          <option value="♰"/>
          
          <option value="☼"/>
          <option value="☀"/>
          <option value="☽"/>
          <option value="☾"/>
          <option value="☁"/>
          <option value="☂"/>
          <option value="☃"/>
          <option value="☄"/>

          <option value="↑"/>
          <option value="↓"/>
        </select>
      </div>
      <local-quill-editor v-model="sendData.subTitle" v-if="sendData.type != '9'"
                    ref="quillEditor_sub_title"
                    :options="editorOption3"
                    @change="onEditorChange($event)">
      </local-quill-editor>
      <h4 id='textcount'> 글자수 {{ textcount }} / 원고지 {{ papers }}매</h4>      
      <div id="toolbar" slot="toolbar">
        <button class="ql-blockquote" style="width: 80px; border-left: 3px solid;">내부부제</button>
        <span class="ql-align">
          <select class="ql-align" title="Align"></select>
        </span>
        <button class="ql-bold">Bold</button>
        <button class="ql-italic">Italic</button>
        <button class="ql-underline">Underline</button>
        <button class="ql-link">Link</button>
        <button class="ql-list" value="ordered">List</button>
        <button class="ql-list" value="bullet">List</button>
        <span class="ql-color">
          <select class="ql-color" title="Colour"></select>
        </span>
        <button class="ql-image">Image</button>
        <button class="ql-video">Video</button>
        <button id="custom-button-1-4" style="width: 50px" @click="customButtonClick($refs.quillEditor_contents.quill, sendData.byline)">매크로</button>
        <select class="ql-customSpecial" style="line-height: 1.8em;">
          <option value="※"/>
          <option value="▲"/>
          <option value="△"/>
          <option value="ⓒ"/>
          <option value="→"/>
          <option value="…"/>
          <option value="·"/>
          <option value="㈜"/>

          <option value="￠"/>
          <option value="￡"/>
          <option value="￥"/>
          <option value="￦"/>
          <option value="₠"/>
          <option value="₡"/>
          <option value="₢"/>
          <option value="₣"/>

          <option value="☚"/>
          <option value="☛"/>
          <option value="☜"/>
          <option value="☝"/>
          <option value="☞"/>
          <option value="☟"/>
          <option value="☢"/>
          <option value="♺"/>

          <option value="♩"/>
          <option value="♪"/>
          <option value="♫"/>
          <option value="♬"/>
          <option value="♭"/>
          <option value="♮"/>
          <option value="♯"/>
          <option value="♰"/>

          <option value="☼"/>
          <option value="☀"/>
          <option value="☽"/>
          <option value="☾"/>
          <option value="☁"/>
          <option value="☂"/>
          <option value="☃"/>
          <option value="☄"/>

          <option value="↑"/>
          <option value="↓"/>
        </select>
      </div>
      <local-quill-editor 
                    v-model="content"
                    class="quillEditor_contents"
                    ref="quillEditor_contents"
                    :options="editorOption"
                    @change="onEditorChange($event)"
                    style="height: calc(100vh - 310px)"
                    >
      </local-quill-editor>
      <div class="news-rel"  v-html="news_rel">
      </div>
      
    </div>
    <!-- <div class="vx-card" style="width: 680px; padding: 5px; margin-left:15px; font-size: 16px; font-family: '맑은고딕','Malgun Gothic'; text-align: justify; line-height: 25px;" v-html="contentView"></div> -->
    <div class="vx-col w-full md:w-1/3">
      <div class="vx-row">
        <div class="vx-col" style="width: 100px; padding: 0px; margin-top: 9px">
          <p>작성자: </p>
        </div>
        <div v-if="activeUserInfo.level >= 7" class="vx-col" style="width: 210px; padding: 0px">
          <v-select class="vs-con-input-label" placeholder="작성자 선택" label="nickname" v-model="tmpWriter"
            :options="writer" @input="setWriter" :clearable="false"/>
        </div>
        <div v-if="activeUserInfo.level < 7" class="vx-col" style="width: 210px; padding: 10px">
          <div>{{ activeUserInfo.name }}</div>
        </div>
      </div>
      <div v-if="activeUserInfo.level >= 7" class="vx-row">
        <div class="vx-col" style="width: 100px; padding: 0px; margin-top: 9px">
          <p>기타 작성자: </p>
        </div>
        <div class="vx-col" style="width: 210px; padding: 0px">
          <v-select class="vs-con-input-label" multiple placeholder="기타 작성자 선택" label="nickname" v-model="tmpWriter2"
             @input="setWriter2" :options="writer"/>
          <!-- <vs-select
            :success="sendData.another_writer != ''"
            :warning="sendData.another_writer == ''"
            placeholder="기타 작성자 선택" taggable
            multiple label="" v-model="tmpWriter2" @change="setWriter2"
            style="height: 39px;"
            >
            <vs-select-item :key="index" :value="item" :text="item.nickname" v-for="(item,index) in writer"/>
          </vs-select> -->
        </div>
      </div>
      <div class="vx-row">
        <div class="vx-col" style="width: 100px; padding: 0px; margin-top: 9px">
          <p>기사 상태: </p>
        </div>
        <div class="vx-col" style="width: 210px; padding: 0px">
          <v-select class="vs-con-input-label" placeholder="기사 상태 선택" label="name" v-model="sendData.state"
            :options="states" :clearable="false"/>
          <!-- <vs-select
            :success="sendData.state != ''"
            :danger="sendData.state == ''"
            placeholder="기사 상태 선택" label="" v-model="sendData.state"
            style="height: 39px;"
            >
            <vs-select-item :key="index" :value="item.code" :text="item.name" v-for="(item,index) in states"/>
          </vs-select> -->
        </div>
      </div>
      <div class="vx-row">
        <div class="vx-col" style="width: 100px; padding: 0px; margin-top: 9px">
          <p>엠바고: </p>
        </div>
        <div class="vx-col" style="width: 210px; padding: 0px;">
          <date-picker v-model="sendData.d_pass" type="datetime" />
          <!-- <datetime type="datetime" v-model="sendData.d_pass"
            :minute-step="1"
            auto
            class="vs-component vs-con-input-label vs-input inputx vs-input-primary"
            placeholder="날짜-시간 선택"
            style="font-size: 14px;"
          /> -->
        </div>
      </div>
      <div class="vx-row">
        <div class="vx-col" style="width: 100px; padding: 0px; margin-top: 9px">
          <p>형태: </p>
        </div>
        <div class="vx-col" style="width: 210px; padding: 0px">
          <v-select class="vs-con-input-label" label="name" v-model="sendData.news_kind"
              :closeOnSelect="true" :options="[{name: '뉴스', code: '1'},{name: '칼럼', code: '2'}]" :clearable="false"/>
        </div>
      </div>
      <div class="vx-row">
        <div class="vx-col" style="width: 100px; padding: 0px; margin-top: 9px">
          <p>카테고리: </p>
        </div>
        <div class="vx-col" style="width: 210px; padding: 0px">
          <v-select class="vs-con-input-label" placeholder="카테고리 선택" label="name" v-model="sendData.newCategory1"
             @input="setNewCategory1" :closeOnSelect="true" :options="newCategories1" :clearable="false"/>
        </div>
      </div>
      <div class="vx-row">
        <div class="vx-col" style="width: 100px; padding: 0px; margin-top: 9px">
          <p>2차 카테고리: </p>
        </div>
        <div class="vx-col" style="width: 210px; padding: 0px">
          <v-select class="vs-con-input-label" placeholder="2차 카테고리 선택" label="name" v-model="sendData.newCategory2"
             @input="setNewCategory2" :closeOnSelect="true" :disabled="newCategory2Disable" :options="newCategories2View"/>
        </div>
      </div>
      <div class="vx-row">
        <div class="vx-col" style="width: 100px; padding: 0px; margin-top: 9px">
          <p>기존 카테고리: </p>
        </div>
        <div class="vx-col" style="width: 210px; padding: 0px">
          <v-select class="vs-con-input-label" placeholder="카테고리 선택" label="name" v-model="sendData.category1"
             @input="setCategory1" :closeOnSelect="true" :options="categories1" :clearable="false"/>
        </div>
      </div>
      <div class="vx-row">
        <div class="vx-col" style="width: 100px; padding: 0px; margin-top: 9px">
          <p>기존 2차 카테고리: </p>
        </div>
        <div class="vx-col" style="width: 210px; padding: 0px">
          <v-select class="vs-con-input-label" placeholder="2차 카테고리 선택" label="name" v-model="sendData.category2"
             @input="setCategory2" :closeOnSelect="true" :disabled="category2Disable" :options="categories2_view"/>
        </div>
      </div>
      <div v-if="!reviewDisable" class="vx-row">
        <div class="vx-col" style="width: 100px; padding: 0px; margin-top: 9px">
          <p>리뷰 평가: </p>
        </div>
        <div class="vx-col" style="width: 210px; padding: 0px">
          <v-select class="vs-con-input-label" placeholder="평가 점수" label="text" v-model="sendData.review_rate"
            :options="reviewOption"/>
        </div>
      </div>
      <div v-if="!siteDisable" class="vx-row">
        <div class="vx-col" style="width: 100px; padding: 0px; margin-top: 4px">
          <p >현장기사: </p>
        </div>
        <div class="vx-col" style="width: 210px; padding: 0px">
          <div style="height: 27px;"><toggle-button color="rgba(115, 103, 240, 1)" v-model="sendData.site" style="top: 2px"/></div>
        </div>
      </div>
      <div class="vx-row">
        <div class="vx-col" style="width: 100px; padding: 0px; margin-top: 9px">
          <p>시리즈: </p>
        </div>
        <div class="vx-col" style="width: 210px; padding: 0px">
          <v-select class="vs-con-input-label" placeholder="시리즈 선택" label="name" v-model="sendData.series"
            @input="setSeries" :options="seriesArray"/>
        </div>
      </div>
      <div class="vx-row">
        <div class="vx-col" style="width: 100px; padding: 0px; margin-top: 9px">
          <p>byline: </p>
        </div>
        <div class="vx-col" style="width: 210px; padding: 0px">
          <vs-input class="inputx" placeholder="byline" v-model="sendData.byline"/>
        </div>
      </div>
      <div class="vx-row">
        <div class="vx-col" style="width: 100px; padding: 0px; margin-top: 9px">
          <p>email: </p>
        </div>
        <div class="vx-col" style="width: 210px; padding: 0px">
          <vs-input class="inputx" placeholder="email" @change="changeEmail" v-model="sendData.email"/>
        </div>
      </div>
      <div class="vx-row">
        <div class="vx-col" style="width: 100px; padding: 0px; margin-top: 7px">
          <p>태그(관련기사): </p>
        </div>
        <div class="vx-col" style="width: 210px; padding: 0px">
          <v-select ref="tagSelect" class="vs-con-input-label vs-tag" @option:created="tagFocusFlg = true;" @search:blur="setSelected" taggable multiple v-model="sendData.tag"/>
        </div>
      </div>
      <div class="vx-row flex-wrap">
        <div class="vx-col" style="width: 100px; padding: 0px; margin-top: 5px">
          <p>썸네일 설정: </p>
          <p v-if="thumbnailFlg" style="margin-top: 30px;"> 썸네일 위치: </p>
          <!-- <p v-if="thumbnailFlg" style="margin-top: 15px;"> 상하 위치: </p> -->
          <p v-if="thumbnailFlg" style="margin-top: 8px;">이미지 번호: </p>
          <!-- <p v-if="thumbnailFlg" style="margin-top: 4px;">썸네일 업로드: </p> -->
        </div>
        <div class="vx-col" style="width: 210px; padding: 0px">
          <toggle-button color="rgba(115, 103, 240, 1)" v-model="thumbnailFlg" style="top: 2px"/>
          <ul class="flex flex-wrap items-center" v-if="thumbnailFlg">
            <li><vs-radio v-model="thumbnailOption" vs-value="crop">자르기</vs-radio></li>
            <li style="margin-right: 50px;"><vs-radio v-model="thumbnailOption" vs-value="fit">전체포함</vs-radio></li>
            <!-- <li v-if="thumbnailFlg"><vs-slider v-model="thumbnail2" style="min-width: 190px;" text-fixed=% /> </li> -->
            <li v-if="thumbnailFlg"><vs-slider v-model="thumbnail" style="min-width: 190px;" text-fixed=% /> </li>
            <li v-if="thumbnailFlg"><input type="text" style="width: 20px;" v-model="thumbnailImageNum"></li>
            <!-- <li v-if="thumbnailFlg"><input type="file" style="width: 200px;" @change="addFile"></li> -->
          </ul>
        </div>
      </div>
      <div class="vx-row flex-wrap">
        <div class="vx-col" style="width: 100px; padding: 0px; margin-top: 5px">
          <p>워터마크: </p>
        </div>
        <div class="vx-col" style="width: 210px; padding: 0px">
          <toggle-button color="rgba(115, 103, 240, 1)" v-model="watermarkFlg" @change="watermarkToggle" style="top: 2px"/>
          <ul class="flex flex-wrap items-center" v-if="watermarkFlg">
            <li><vs-radio v-model="sendData.watermark" vs-value="northwest">좌상단</vs-radio>
            </li>
            <li><vs-radio v-model="sendData.watermark" vs-value="north">상단</vs-radio>
            </li>
            <li><vs-radio v-model="sendData.watermark" vs-value="northeast">우상단</vs-radio>
            </li><br/>
            <li><vs-radio v-model="sendData.watermark" vs-value="southwest">좌하단</vs-radio>
            </li>
            <li><vs-radio v-model="sendData.watermark" vs-value="south">하단</vs-radio>
            </li>
            <li><vs-radio v-model="sendData.watermark" vs-value="southeast">우하단</vs-radio>
            </li><br/>
          </ul>
        </div>
      </div>
      <div class="vx-row flex-wrap" style="margin-top: 5px">
        <div class="vx-col" style="width: 100px; padding: 0px; margin-top: 15px">
          <p>속보: </p>
        </div>
        <div class="vx-col" style="width: 210px; padding: 0px">
          <ul class="flex flex-wrap items-center">
            <li><md-radio v-model="sendData.fastnews" value="1">미분류</md-radio>
            </li>
            <li><md-radio v-model="sendData.fastnews" value="fast">속보</md-radio>
            </li>
            <li><md-radio v-model="sendData.fastnews" value="single">단독</md-radio>
            </li><br/>
            <li><md-radio v-model="sendData.fastnews" value="topic" style="width: 65px;">화제</md-radio>
            </li>
            <li><md-radio v-model="sendData.fastnews" value="issue">이슈</md-radio>
            </li>
            <li><md-radio v-model="sendData.fastnews" value="an">분석</md-radio>
            </li><br/>
          </ul>
        </div>
      </div>
      <div class="vx-row flex-wrap" style="margin-top: 5px">
        <div class="vx-col" style="width: 100px; padding: 0px; margin-top: 15px">
          <p>외부송고: </p>
        </div>
        <div class="vx-col" style="width: 210px; padding: 0px">
          <p @click="familySiteToggle" class="cursor-pointer">전체선택</p>
          <md-checkbox value="2" v-model="familySite" >
            <span>화학</span>
          </md-checkbox>
          <md-checkbox value="3" v-model="familySite" >
            <span>바다</span>
          </md-checkbox>
          <md-checkbox value="4" v-model="familySite" >
            <span>스틸</span>
          </md-checkbox>
        </div>
      </div>
      <div class="vx-row flex-wrap" style="margin-top: 5px">
        <div class="vx-col" style="width: 100px; padding: 0px; margin-top: 3px">
          <p>유료기사: </p>
        </div>
        <div class="vx-col" style="width: 210px; padding: 0px">
          <toggle-button color="rgba(115, 103, 240, 1)" :disabled="familySite.length == 0" v-model="newsfreeFlg" :sync="true"/>
        </div>
      </div>
      <div class="vx-row flex-wrap" style="margin-top: 20px">
        <div class="vx-col" style="width: 100px; padding: 0px; margin-top: 3px">
          <p>포털전송: </p>
        </div>
        <div class="vx-col" style="width: 210px; padding: 0px">
          <toggle-button color="rgba(115, 103, 240, 1)" v-model="portalFlg" />
        </div>
      </div>
      <div style="margin-top: 20px">
        <vs-button :disabled="previewDisable" color="success" type="filled" v-on:click="dataToArticle(function(){})" >미리보기 ({{sendButtonText}})</vs-button> 
      </div>
      <div style="margin-top: 20px; padding: 0px;">
        <textarea v-model="lockHistory" style="width: 280px; height: 200px; text-align: right;" />
      </div>

    </div>
          

    <!-- Start: 미리보기 팝업 -->
    <div id="div-with-loading" class="news-detail-form vs-con-loading__container">
      <vs-popup ref="popup-newsDetail" classContent="popup-newsDetail" title="미리보기" :active.sync="newsDetailModalActive">
        <div class="vx-row mb-6">
          <div class="news-detail">
            <div v-html="contentView">
            </div>
            <div class='news-rel'><h3>관련기사</h3>
              <ul>
                <li v-for="(newsRel, idx) in sendData.rel" :key="idx">
                  <a :href="'https://www.ebn.co.kr/news/view/' + newsRel.idx" target="_blank" v-html="newsRel.title"></a>
                </li>
              </ul>
            </div>
            <div style=" display: flex; margin: 80px 0 20px; padding: 20px 60px; border-top: 1px solid #ccc; border-bottom: 1px solid #ccc;"><div style="height: 50px; width: 100%; background-color: aliceblue;"></div></div>
            <div style="width: 100%; padding-bottom: 80px;">
              <vs-button style="float: right" id='sendButton' :disabled="sendButtonDisable" color="primary" type="filled" v-on:click="sendDataToServer()" >{{sendButtonText}}</vs-button>
              <div>
                <vs-input class="inputx" placeholder="기사 검색" v-model="searchText" @keyup.enter="getNewsList()" style="display: inline-block;"/>
                <vs-button color="primary" type="border" v-on:click="getNewsList()" >검색</vs-button>
                <vs-button color="danger" type="border" v-on:click="swipeRelList()" >비우기</vs-button>
              </div>
              <div>
                <div v-for="(newsRel, idx) in newsRelListSearch" :key="idx">
                  <md-checkbox @change="newsRelChange(newsRel, idx)" :value="newsRel" v-model="sendData.rel" >
                    <b v-if="sendData.rel.find(e => e.idx == newsRel.idx)" v-html="newsRel.idx + ' | ' + newsRel.title.replace(/<\/?strong>/g, '')"></b>
                    <span v-else v-html="newsRel.idx + ' | ' + newsRel.title.replace(/<\/?strong>/g, '')"></span>
                  </md-checkbox>
                </div>
              </div>
              <div style="width: 100%; border: 1px solid black;"></div>
              <div>
                <div v-for="(newsRel, idx) in newsRelList" :key="idx">
                  <md-checkbox @change="newsRelChange(newsRel, idx)" :value="newsRel" v-model="sendData.rel" >
                    <b v-if="sendData.rel.find(e => e.idx == newsRel.idx)" v-html="newsRel.idx + ' | ' + newsRel.title.replace(/<\/?strong>/g, '')"></b>
                    <span v-else v-html="newsRel.idx + ' | ' + newsRel.title.replace(/<\/?strong>/g, '')"></span>
                  </md-checkbox>
                </div>
              </div>
            </div>
          </div>
        </div>
      </vs-popup>
    </div>
    <!-- End: 미리보기 팝업 -->
      
  </div>
</template>

<script>
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import moment from 'moment';
import VueQuillEditor from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import { ImageDrop } from 'quill-image-drop-module'
import ImageResize from 'quill-image-resize-module'
VueQuillEditor.Quill.register('modules/imageDrop', ImageDrop)
VueQuillEditor.Quill.register('modules/imageResize', ImageResize)
export default {
  data:()=>({
    form: {
      actives: {
        category1: false,
        category2: false
      },
      data: {
        category1: {
          categories: [],
          toString: "1차 카테고리 선택"
        },
        category2: {
          categories: [],
          toString: "2차 카테고리 선택"
        }
      }
    },
    sendData :{
      id: '',
      writer: '',
      mno1: '33252',
      email: '',
      text: '',
      Images: [],
      icon: '',
      mainTitle: '',
      subTitle: '', 
      state: {'code': ''}, 
      news_kind: {name: '뉴스', code: '1'},
      type: '1',
      site: false,
      watermark: '',
      thumbnail: '',
      category1: [],
      category2: [],
      newCategory1: [],
      newCategory2: [],
      series: {'code': ''},
      review_rate: {'rate': ''},
      byline: '',
      d_pass: '',
      g_money: 0,
      s_money: 0,
      name: '',
      tailname: '',
      tag: [],
      rel: [],
      portalAction: [],
      out_site: '',
      familySiteSend: '',
      fastnews: '1',
      news_free: '1',
      out_state: '0',
    },
    familySite: [],
    newsfreeFlg: false,
    portalFlg: false,
    watermarkFlg: false,
    thumbnailFlg: false,
    thumbnailOption: 'crop',
    thumbnailImageNum: 1,
    thumbnail: 0,
    thumbnail2: [0,100],
    photoData: [],
    pastState: '9',
    lockHistory: '',
    searchText: '',
    newsRelList: [],
    newsRelListSearch: [],
    tmpCategory1: [],
    tmpCategory2: [],
    tmpWriter: {},
    tmpWriter2: [],
    writer: [],
    types: [],
    states: [],
    portals: [],
    categories1: [],
    categories1_ok: false,
    categories2: [],
    categories2_view: [],
    category2Disable: true,
    newCategories1: [],
    newCategories2: [],
    newCategories2View: [],
    newCategory2Disable: true,
    seriesArray: [],
    sendButtonDisable: true,
    sendButtonText: '기사 저장',
    previewDisable: true,
    textcount: 0,
    papers:0,
    content: '',
    contentView: '',
    news_rel: '',
    siteDisable: true,
    reviewDisable: true,
    tooltipDisable: false,
    tagFocusFlg: false,
    reviewOption: [{rate: 5, text: '★★★★★'},
                   {rate: 4.5, text: '★★★★☆'},
                   {rate: 4, text: '★★★★'},
                   {rate: 3.5, text: '★★★☆'},
                   {rate: 3, text: '★★★'},
                   {rate: 2.5, text: '★★☆'},
                   {rate: 2, text: '★★'},
                   {rate: 1.5, text: '★☆'},
                   {rate: 1, text: '★'},
                   {rate: 0.5, text: '☆'}],
    newsDetailModalActive: false,
    editorOption: {
      modules: {
        // toolbar: '#toolbar',//[['bold', 'italic', 'underline'], ['blockquote'], [{ 'list': 'ordered'}, { 'list': 'bullet' }], [{ 'color': [] }], ['link', 'image', 'video']],
        toolbar: {
          container: '#toolbar',
          // container:[
          //   ['bold', 'italic', 'underline'], [{ 'list': 'ordered'}, { 'list': 'bullet' }], [{ 'color': [] }], ['link', 'image', 'video'],
          //   [{'customMacro': '매크로'}], [{'customSpecial': ['ㅁ', 'ㅇ', 'ㅎ']}]
          // ],
          handlers: {
            "customSpecial": function (value) { 
              if (value || value == '') {
                console.log(value, this)
                const cursorPosition = this.quill.getSelection().index;
                this.quill.insertText(cursorPosition, value);
                this.quill.setSelection(cursorPosition + value.length);
              }
            },
            // "customMacro": function (value) { 
            //   if (value) {
            //     console.log(value, this)
            //     const cursorPosition = this.quill.getSelection().index;
            //     this.quill.insertText(cursorPosition, value);
            //     this.quill.setSelection(cursorPosition + value.length);
            //   }
            // }
          }
        },
        imageDrop: true,
        imageResize: {
            modules: [ 'Resize', 'DisplaySize', 'Toolbar' ]
        },
      },
      theme: 'snow',
      placeholder: '내용을 입력하세요.'
    },
    editorOption2: {
      modules: {
        toolbar: {
          container: '#toolbar2',
          handlers: {
            "customSpecial": function (value) { 
              if (value) {
                console.log(value, this)
                const cursorPosition = this.quill.getSelection().index;
                this.quill.insertText(cursorPosition, value);
                this.quill.setSelection(cursorPosition + value.length);
              }
            },
          }
        },
      },
      theme: 'snow',
      placeholder: '내용을 입력하세요.'
    },
    editorOption3: {
      modules: {
        toolbar: {
          container: '#toolbar3',
          handlers: {
            "customSpecial": function (value) { 
              if (value) {
                console.log(value, this)
                const cursorPosition = this.quill.getSelection().index;
                this.quill.insertText(cursorPosition, value);
                this.quill.setSelection(cursorPosition + value.length);
              }
            },
          }
        },
      },
      theme: 'snow',
      placeholder: '내용을 입력하세요.'
    },
    editorOption4: {
      modules: {
        toolbar: {
          container: '#toolbar4',
          handlers: {
            "customSpecial": function (value) { 
              if (value) {
                console.log(value, this)
                const cursorPosition = this.quill.getSelection().index;
                this.quill.insertText(cursorPosition, value);
                this.quill.setSelection(cursorPosition + value.length);
              }
            },
          }
        },
      },
      theme: 'snow',
      placeholder: '내용을 입력하세요.'
    },
    prevRoute: '',
    initImage: false,
    timeFlg: false,
    autoSaveFlg: false,
    autoSaved: false,
    lockFlg: true,
    delFlg: false,
  }),
  beforeRouteEnter(to, from, next) {
    next(vm => {
      // console.log(from)
      vm.prevRoute = from
    })
  },
  beforeRouteLeave (to, from, next) {
    console.log('autosave: ', this.autoSaveFlg)
    const thisIns = this;
    if(this.autoSaveFlg && confirm('기사를 저장하시겠습니까?')){
      this.autoSaved = true;
      this.dataToArticle((data) =>{
        if(!data){
          thisIns.newsDetailModalActive = false;
          thisIns.sendDataToServer();
          setTimeout(() => { next(); }, 100);
        }
      });
    }else{
      this.newsDetailModalActive = false;
      setTimeout(() => { next(); }, 100);
    }
    if(this.lockFlg){
      console.log('open:', this.lockFlg)
      this.$http.post('/api/writeArticles/lock', {id: this.sendData.id, name: this.$store.state.AppActiveUser.name, mno1: this.$store.state.AppActiveUser.id, lock: 'open'})
        .catch(function (error) {
          console.log(error);
        });
    }
  },
  watch: {
    '$route.query': function() {
      console.log(this.$route.query);
      this.newsDetailModalActive = false;
      this.created();
    },
    'sendData.state': function() {
      if(this.sendData.state == this.states.find(e => e.code == '1')) this.sendButtonText = '기사 전송'
      else  this.sendButtonText = '기사 저장'
    }

  },
  components: {
    LocalQuillEditor: VueQuillEditor.quillEditor,
    DatePicker,
  },
  methods: {
    deleteArticle(){
      const thisIns = this;
      if(confirm('기사를 삭제하시겠습니까?')){
        this.$http.post('/api/writeArticles/delete', { id: this.sendData.id, delFlg: false, reporter: this.activeUserInfo.id })
          .then(function (res) {
            console.log(res); 
            var newPath = thisIns.prevRoute.path;
            var newQuery = thisIns.prevRoute.query;
            if(!thisIns.prevRoute.path || thisIns.prevRoute.path.includes('login')) {
              newPath = '/newsList';
              newQuery = '';
            }
            thisIns.autoSaveFlg = false;
            thisIns.$router.push({path: newPath, query: newQuery });
            alert('기사를 삭제하였습니다.')
          })
          .catch(function (error) {
            var errorText = error.toString();
            if(errorText.includes('401')){
              alert('로그인 해주세요!')
              thisIns.$router.push({path: '/pages/login?to='+ thisIns.$router.history.current.path, query: thisIns.$router.history.current.query})
            }
            thisIns.$vs.notify({
              title:'Error',
              text: error,
              color:'danger',
              iconPack: 'feather',
              icon:'icon-alert-circle'})
          });
      }
    },
    familySiteToggle(){
      console.log(this.familySite)
      if(this.familySite.length >= 3) 
      { 
        this.familySite = []
      }
      else { 
        this.familySite = ["2","3","4"] 
      }
    },
    newsRelChange(){
      if(this.sendData.rel.length > 5){
        this.sendData.rel.splice(5);
        // this.newsRelList[idx].flg = false;
        // console.log(this.newsRelList)
        this.$vs.notify({
          title:'Error',
          text: '관련기사는 최대 5개까지 입력가능합니다.',
          color:'danger',
          iconPack: 'feather',
          icon:'icon-alert-circle'})
      }
    },
    swipeRelList(){
      this.sendData.rel = [];
    },
    getNewsList() {
      let data = {
        startRow: 0,
        itemsPerPage: 15,
        state: 1,
        searchText: this.searchText,
        startDate: moment().subtract(6, 'month').format('YYYY-MM-DD'),
        endDate: moment().add(2, 'week').format('YYYY-MM-DD'),
        department: '',
        level: this.activeUserInfo.level,
        id: this.activeUserInfo.id,
      }
      
      this.$vs.loading({
        scale: 1.5
      });
      const thisIns = this;
      this.$http.post('/api/getNewsList', data)
        .then(function (response) {
          thisIns.newsRelListSearch = response.data[1].filter(e => thisIns.newsRelList.findIndex(e2 => e2.idx == e.idx) < 0);
          for(var i in thisIns.sendData.rel){
            let tmpRel = thisIns.newsRelListSearch.findIndex(e => e.idx == thisIns.sendData.rel[i].idx);
            if( tmpRel > -1 ){
              thisIns.sendData.rel[i] = thisIns.newsRelListSearch[tmpRel];
            }
          }
          // console.log(thisIns.newsRelListSearch)
          thisIns.$vs.loading.close();          
        })
        .catch(function (error) {
          var errorText = error.toString();
          if(errorText.includes('401')){
            alert('로그인 해주세요!')
            thisIns.$router.push({path: '/pages/login?to='+ thisIns.$router.history.current.path, query: thisIns.$router.history.current.query})
          }
          thisIns.$vs.notify({
            title:'Error',
            text: error,
            color:'danger',
            iconPack: 'feather',
            icon:'icon-alert-circle'})
          thisIns.$vs.loading.close(); 
        });
    },
    sendDataToServer(){ //<-----------------------------------------------------------------------------------------Server 보내는 함수
      if(this.sendData.state.code == '1' && moment(this.sendData.d_pass).format('X') > moment().format('X')){
          this.$vs.notify({
            title:'Error',
            text: '엠바고 시간이 되지 않아 송고완료할 수 없습니다.',
            color:'danger',
            iconPack: 'feather',
            icon:'icon-alert-circle'})
          return;
      }
      this.$vs.loading({
        scale: 1.5
      });
      // this.sendData.mainTitle = this.sendData.mainTitle;
      this.sendData.portalAction = [];
      let tmpOutSite = this.sendData.out_site.split("|");
      // this.sendData.portalAction[0] = {'id': 10, 'name': 'BRIC', 'oldName': 'bric'};
      // if(tmpOutSite.find(e => e.toLowerCase() == this.sendData.portalAction[0].name.toLowerCase() || e == this.sendData.portalAction[0].oldName )) this.sendData.portalAction[0]['action'] = "U";
      // else this.sendData.portalAction[0]['action'] = "I";
      if(this.portalFlg){
        this.sendData.out_state = '1';
        console.log('out_site: ', tmpOutSite);
        for(var i = 0; i<this.portals.length; i++){
          this.sendData.portalAction.push(this.portals[i]);
          if(tmpOutSite.find(e => e.toLowerCase() == this.sendData.portalAction[i].name.toLowerCase() || e == this.sendData.portalAction[i].oldName )) this.sendData.portalAction[i]['action'] = "U";
          else this.sendData.portalAction[i]['action'] = "I";
        }
      }
      console.log('portalAction: ', this.sendData.portalAction);
      // let sqareThumbnail = this.sendData.category1.findIndex(e => e.code == 11);
      // if(this.thumbnailFlg){
      //   this.sendData.thumbnail = sqareThumbnail + ';' + this.thumbnail + ';' + this.thumbnailOption + ';' + this.thumbnailImageNum;
      // }else{
      //   this.sendData.thumbnail = sqareThumbnail + ';;';
      // }
      if(this.thumbnailFlg){
        this.sendData.thumbnail = this.thumbnail2 + ';' + this.thumbnail + ';' + this.thumbnailOption + ';' + this.thumbnailImageNum;
      }else{
        this.sendData.thumbnail = this.thumbnail2 + ';;;';
      }
      if(this.sendData.portalTitle == ''){
        this.sendData.portalTitle = this.sendData.mainTitle;
      }
      this.sendData.familySiteSend = '^' + this.familySite.join('^');
      if(this.newsfreeFlg && this.familySite.length > 0){
        this.sendData.news_free = '3';
      }else{
        this.sendData.news_free = '1';
      }
      // console.log(this.sendData.familySiteSend)
      const thisIns = this;
      // console.log('this.sendData:', this.sendData, this.sendData.subTitle.split('</p><p>').join('\n').split('<p>').join('').split('</p>').join(''))
      this.$http.post('/api/writeArticles', this.sendData)
        .then(function () {
          // console.log(response, thisIns.$router, thisIns.prevRoute)
          thisIns.newsDetailModalActive = false;
          thisIns.$vs.loading.close(); 
          var newPath = thisIns.prevRoute.path;
          var newQuery = thisIns.prevRoute.query;
          if(!thisIns.prevRoute.path || thisIns.prevRoute.path.includes('login')) {
            newPath = '/newsList';
            newQuery = '';
          }
          if(!thisIns.autoSaved){
            thisIns.autoSaveFlg = false;
            thisIns.$router.push({path: newPath, query: newQuery });
            alert('기사를 저장하였습니다.')
          }
        })
        .catch(function (error) {
          var errorText = error.toString();
          if(errorText.includes('401')){
            alert('로그인 해주세요!')
            thisIns.$router.push({path: '/pages/login?to='+ thisIns.$router.history.current.path, query: thisIns.$router.history.current.query})
          }
          thisIns.$vs.notify({
            title:'Error',
            text: error,
            color:'danger',
            iconPack: 'feather',
            icon:'icon-alert-circle'})
          thisIns.$vs.loading.close();  
        });
    },
    onEditorChange(event) {
      if(!this.initImage && this.timeFlg) this.autoSaveFlg = true;
      this.sendButtonDisable = true;
      
      let lines = event.quill.getLines();
      for(const line of lines){
        for(const lineChild of line.domNode.childNodes){
          // console.log('color: ', lineChild.style.color, lineChild.style.color.indexOf("rgb(98, 98, 98)"))
          if(lineChild.style && (lineChild.style.color.indexOf("rgb(98, 98, 98)") > -1 
                                || lineChild.style.color.indexOf("rgb(119, 119, 119)") > -1
                                || lineChild.style.color.indexOf("rgb(0, 0, 0)") > -1
                                || lineChild.style.color.indexOf("rgb(20, 20, 20)") > -1
                                || lineChild.style.color.indexOf("rgb(17, 17, 17)") > -1
                                || lineChild.style.color.indexOf("black") > -1)){
            lineChild.style.color = "";
          }
        }
      }
      // console.log(this.sendData.mainTitle.replace(/<\/?(span[^>]*|u[^>]*|em[^>]*|strong[^>]*)>/g, ""), event.quill,event.quill.getLine(0));
      if(event.quill == this.$refs.quillEditor_title.quill){
        if(event.quill.getLines().length > 1) {
          event.quill.setContents(event.quill.getLines()[0].cache.delta);
          // event.quill.deleteText(event.quill.getLines()[0].length,1);
        }else if(event.quill.root.innerHTML.match('</?(strong[^>]*)>')){
          event.quill.root.innerHTML = event.quill.root.innerHTML.replace(/<\/?(strong[^>]*)>/g,'');
        }
      }
      else if(this.$refs.quillEditor_sub_title && event.quill == this.$refs.quillEditor_sub_title.quill){
        if(event.quill.getLines().length > 4) {
          event.quill.setContents(event.quill.getLines()[0].cache.delta
                                  .concat(event.quill.getLines()[1].cache.delta)
                                  .concat(event.quill.getLines()[2].cache.delta)
                                  .concat(event.quill.getLines()[3].cache.delta));
        }else if(event.quill.root.innerHTML.match('background-color:[^;]*;')){
          event.quill.root.innerHTML = event.quill.root.innerHTML.replace(/background-color:[^;]*;/g,'');
        }else if(event.quill.root.innerHTML.match('</?(strong[^>]*)>')){
          event.quill.root.innerHTML = event.quill.root.innerHTML.replace(/<\/?(strong[^>]*)>/g,'');
        }
      }
      else if(event.quill == this.$refs.quillEditor_contents.quill) {
        if(event.quill.root.innerHTML.match('&nbsp;')){
          event.quill.root.innerHTML = event.quill.root.innerHTML.replace(/&nbsp;\s?&nbsp;/g,'</p><p><br></p><p>').replace(/<p>\s?&nbsp;\s?<\/p>/g,'<p><br></p>').replace(/&nbsp;/g,'');
        }
        this.countCharacter(event);
      }
    },
    setSelected(){
      console.log(this.$refs.tagSelect.$el.querySelector('input'));
      if(this.tagFocusFlg) this.$refs.tagSelect.$el.querySelector('input').focus();
      this.tagFocusFlg = false;
    },
    loadData(data){
      var textTemp = data;
      textTemp = textTemp.replace(/<div class="editor-subtitle type.?">((?:(?:(?!<div[^>]*>|<\/div>).)+|<div[^>]*>[\s\S]*?<\/div>)*)<\/div>/g, "<blockquote>$1</blockquote>")
      textTemp = textTemp.replace(/<div class="editor-middle-title type.?">((?:(?:(?!<div[^>]*>|<\/div>).)+|<div[^>]*>[\s\S]*?<\/div>)*)<\/div>/g, "<h6>$1</h6>")
      var root="https://cdnimage.ebn.co.kr/news/";
      var i = 1;
      var tmp;
      var imgText1 = '<p><img src="';
      var imgText2 = '" width="';
      var imgText3 = '" height="';
      var imgText4 = '"></p>';
      while (textTemp.match('<!---'+i+'--->')){
        tmp = textTemp.match('<!---'+i+'--->')
        let width = this.sendData.images[i-1].width * 1;
        if(!width){ width = ''; }
        textTemp = textTemp.replace(tmp[0],imgText1 + root + this.sendData.images[i-1].filename + imgText2 + width  + imgText3 + imgText4 + "<p>" + this.sendData.images[i-1].caption + "</p>");
        i++;
      }
      this.initImage = true;
      return textTemp;
    },
    dataToArticle(cb){
      if(this.sendData.mainTitle == '' && this.sendData.portalTitle){ this.sendData.mainTitle = this.sendData.portalTitle; }
      else if(this.sendData.portalTitle == '' && this.sendData.mainTitle){ this.sendData.portalTitle = this.sendData.mainTitle; }
      this.sendData.images = [];
      console.log('content: ', this.content, this.sendButtonDisable)
      var textTemp = this.content.replace(/<p> ?&nbsp; ?<\/p>/g, '<p><br></p>').replace(/&nbsp;/g,'');
      var tmp, tmp2, tmp3, tmp4 =[]; 
      var tmpWidth, i=1;
      tmp2 = textTemp.match(/<\/blockquote><blockquote>/g);
      if(tmp2){
        for(var j in tmp2){
          textTemp = textTemp.replace(tmp2[j], "<br>");
        }
      }
      tmp2 = textTemp.match(/<\/h6><h6>/g);
      if(tmp2){
        for(j in tmp2){
          textTemp = textTemp.replace(tmp2[j], "<br>");
        }
      }
      tmp2 = textTemp.match(/<iframe.*?>(.*?)<\/iframe>/g)
      if(tmp2){
        console.log('iframe: ', tmp2)
        for(j in tmp2){
          textTemp = textTemp.replace(tmp2[j], tmp2[j].slice(0,tmp2[j].indexOf('src=')) + `style="width: 640px; height: 360px;" ` + tmp2[j].slice(tmp2[j].indexOf('src=')));
        }
      }
      while(textTemp.match(/<img[^>]*src=["']?([^>"']+)["']?[^>]*>/)){
        tmp = textTemp.match(/<img[^>]*src=["']?([^>"']+)["']?[^>]*>/)
        console.log('imgtag: ', tmp)
        // this.sendData.arrayBase64.push(tmp[1]);
        tmp3 = tmp[0].match("display:?([^;]+)[;]")
        if(tmp3)
        {
          console.log('dddd',tmp3)
          if(tmp3[1] == ' block'){
            console.log('block')
          }else if(tmp3[1] == ' inline'){
            tmp3 = tmp[0].match("float:?([^;]+)[;]")
            console.log(tmp3[1])
          }
        }
        if(tmp3){
          if(tmp3[1] == ' block') tmp3 = "C";
          else if(tmp3[1] == ' left') tmp3 = "L";
          else if(tmp3[1] == ' right') tmp3 = "R";
        }
        else tmp3 = "C";

        console.log('result_tmp3:', tmp3)
        // this.sendData.arrayImageAlign.push(tmp3);
        tmpWidth = tmp[0].match(/width="?([^"]+)["]/)
        if(tmpWidth){
          if(tmpWidth[1] > this.$store.state.maxWidth) tmpWidth = this.$store.state.maxWidth;
          else tmpWidth = tmpWidth[1];
        }else tmpWidth = "";
        // this.sendData.arrayImageWidth.push(tmpWidth);

        var imgTag = '<!---'+i+'--->';
        var tmpCaption = '';
        textTemp = textTemp.replace(tmp[0],imgTag);
        tmp4 = textTemp.match(imgTag+"(<span class=\"ql-cursor\">\uFEFF</span>)?(</p><p[^>]*>)?(.*?)</p>")
        if (tmp4){
          if(tmp4[3].match(/<img[^>]*src=["']?([^>"']+)["']?[^>]*>/)){
            console.log(tmp4[3], tmp4[3].indexOf('<img'));
            textTemp = textTemp.replace(tmp4[0].substring(0,tmp4[0].indexOf('<img')),imgTag + "</p>");
            tmpCaption = tmp4[3].substring(0,tmp4[3].indexOf('<img'));
          }else {
            // console.log('tmp4: ', tmp4)
            textTemp = textTemp.replace(tmp4[0],imgTag + "</p>");
            tmpCaption = tmp4[3].replace(/<\/?([a-z]+[^>]*)>/g,'');
            // this.sendData.arrayImageText.push(tmp4[1]);
          }
        }else tmpCaption = '';//this.sendData.arrayImageText.push('');
        var pno = 0;
        var photoIndex = -1;
        console.log('this.photoData:', this.photoData)
        if((photoIndex = this.photoData.findIndex(e => tmp[1].includes(e.filename))) >= 0){
          pno = this.photoData[photoIndex].pno;
        }
        // console.log('pno:', pno, tmp[1])
        this.sendData.images.push({'filename': tmp[1], 'caption': tmpCaption, 'width': tmpWidth, 'align': tmp3, 'pno': pno})
        i++;
      }
      this.sendData.text = textTemp.replace(/<(|p[^>]*)> ?<\/p>/g, "");
      this.sendData.subTitle = this.sendData.subTitle.replace(/background-color:[^;]*;/g, '').split('<p><br></p>').join('').substring(0,499);
      this.contentsToArticle(textTemp, this.sendData.images, this.sendData.mainTitle, this.sendData.subTitle, 
                          moment(this.sendData.d_pass).format('YYYY-MM-DD HH:mm'), moment().format('YYYY-MM-DD HH:mm'), this.sendData.byline.replace('EBN ', ''), 
                          this.sendData.email, this.sendData.review_rate, (data)=>{
        this.contentView = data;
        // console.log('미리보기', this.contentView)
        let alertText = '';
        if(this.sendData.mainTitle == '' && this.sendData.portalTitle == ''){
          alertText += '제목, ';
        }
        if(!this.sendData.newCategory1) alertText += '1차 카테고리, ';
        // if(!this.category2Disable && !this.sendData.category2) alertText += '2차 카테고리, ';
        if(alertText != ''){
          let alertText2 = '';
          if(alertText == '제목, ') alertText2 = '을 입력해주세요!'
          else alertText2 = '를 입력해주세요!'
          alert(alertText.substring(0,alertText.length-2) + alertText2);
          this.sendButtonDisable = true;
        }else this.sendButtonDisable = false;
        cb(this.sendButtonDisable);
      });
    },
    contentsToArticle(contents, imgOption, mainTitle, subTitle, d_pass, d_modi, name, email, review_rate, cb){
      this.$vs.loading({
        scale: 1.5
      });
      const thisIns = this;
      var root="./news/";
      const maxWidth = this.$store.state.maxWidth;
      if(this.thumbnailFlg){
        this.sendData.thumbnail = this.thumbnail2 + ';' + this.thumbnail + ';' + this.thumbnailOption + ';' + this.thumbnailImageNum;
      }else{
        this.sendData.thumbnail = this.thumbnail2 + ';;;';
      }
      this.activeUserInfo.watermark = this.sendData.watermark;
      console.log('sqareThumbnail:', this.sendData.thumbnail, this.sendData.icon)
      this.$http.post('/api/writeArticles/preview', {'id': this.sendData.id, 'images': imgOption, 'thumbnail': this.sendData.thumbnail, 'watermark': this.sendData.watermark, 'tag': this.sendData.tag, icon: this.sendData.icon })
        .then(function (data) {
          console.log(data)
          thisIns.newsDetailModalActive = true;
          let thumbnailImageNum = parseInt(thisIns.thumbnailImageNum);
          if((!thumbnailImageNum || thumbnailImageNum > imgOption.length) && thumbnailImageNum != 0) { thumbnailImageNum = 1; }
          thisIns.newsRelList = data.data.tags;
          console.log(thisIns.newsRelList)
          if(!thisIns.sendData.rel.length){ thisIns.sendData.rel = thisIns.newsRelList.slice(0,5); }
          else{ 
            for(var i in thisIns.sendData.rel){
              let tmpRel = thisIns.newsRelList.findIndex(e => e.idx == thisIns.sendData.rel[i].idx);
              if( tmpRel > -1 ){
                thisIns.sendData.rel[i] = thisIns.newsRelList[tmpRel];
              }
            }
          }
          // var rel_news = thisIns.newsRelList.slice(0,5);
          thisIns.sendData.images = data.data.images;
          thisIns.sendData.icon = data.data.icon;
          // if(thumbnailImageNum == 0){ thisIns.sendData.icon = ''; }
          imgOption = data.data.images;
          var imgAlign = [];
          for(i=0;i<imgOption.length;i++){
            if(imgOption[i].align == 'C') imgAlign[i] = '';
            else if(imgOption[i].align == 'L') imgAlign[i] = 'margin: 0 15px; float: left;';
            else if(imgOption[i].align == 'R') imgAlign[i] = 'margin: 0 15px; float: right';
          }
          let article;
          var textTemp = "";
          if(imgOption.length && thumbnailImageNum){
            // thisIns.sendData.icon = imgOption[thumbnailImageNum-1].filename.substring(0, imgOption[thumbnailImageNum-1].filename.indexOf(`m_${thumbnailImageNum}`)) + "c.jpg"
            textTemp = "<div style='margin-bottom: 20px;'><div><span style='margin-left: 150px;'>일반</span><span style='margin-left: 350px;'>메인 배치</span></div><img src=" + root + "icon/" + thisIns.sendData.icon + ">"
            + "<img src=" + root + "icon/" + thisIns.sendData.icon.replace("_c", "_c3") + " style='margin-left: 20px; width:400px;'></div>";
          }
          textTemp += "<h2>" + mainTitle + "</h2><div class='information'><ul><li>입력 " 
                          + d_pass + " | 수정 " + d_modi + "</li><li>" + name + "</li></ul></div><div class='article'>";
          if(subTitle){
            textTemp += "<div class='inner-subtitle'>" + subTitle + "</div>";
          }
          textTemp += contents + "</div>";
          i = 1;
          var tmp = [];
          var tableText1 = "<div class='figure' style='width: ";
          var tableText2 = "'><img src='";
          var tableText3 = "' width='";
          var tableText4 = "px' alt='";
          var tableText5 = "'><span>";
          var tableText6 = "</span></div>";

          while (textTemp.match('<!---'+i+'--->')){
            tmp = textTemp.match('<!---'+i+'--->')
            if(imgOption[i-1].width > maxWidth) imgOption[i-1].width = maxWidth;
            textTemp = textTemp.replace(tmp[0],tableText1 + imgOption[i-1].width + 'px;' + imgAlign[i-1] + tableText2 
                                      + root + imgOption[i-1].filename + tableText3 + imgOption[i-1].width 
                                      + tableText4 + tableText5
                                      + imgOption[i-1].caption + tableText6);
            i++;
          }
          if(review_rate && review_rate.rate){
              let stars = '';
              for(i=0; i<review_rate.rate;i++){
                  if(review_rate.rate - i >= 1) stars += "★";
                  else stars += "☆";
              }
              textTemp += "<p><br></p><p>리뷰점수: " + stars + "</p>";
          }
          article = textTemp// + `<div style=" display: flex; margin: 80px 0 20px; padding: 20px 60px; border-top: 1px solid #ccc; border-bottom: 1px solid #ccc;"><div style="height: 50px; width: 100%; background-color: aliceblue;"></div></div>`;
          console.log('생성된 미리보기: ',article)
          thisIns.$vs.loading.close(); 
          cb(article);
        })
        .catch(function (error) {
          var errorText = error.toString();
          if(errorText.includes('401')){
            alert('로그인 해주세요!')
            thisIns.$router.push({path: '/pages/login?to='+ thisIns.$router.history.current.path, query: thisIns.$router.history.current.query})
          }
          thisIns.$vs.notify({
            title:'Error',
            text: error,
            color:'danger',
            iconPack: 'feather',
            icon:'icon-alert-circle'})
          thisIns.$vs.loading.close();  
          cb(contents);
        });
    },
    countCharacter(event) {
      // console.log(event.quill); //this.content.match(/<img[^>]*src=["']?([^>"']+)["']?[^>]*>(<\/p><p>)?(.*?)<\/p>/))
      var lines = event.quill.getLines();
      var captionFlg = false;
      var imgIdx = 0;
      for(var i in lines){
        // console.log(lines[i])
        // if(lines[i].domNode.tagName == 'DIV' && lines[i].domNode.className.indexOf("editor-subtitle") > -1){
        //   lines[i].domNode.outerHTML = `<blockquote>${lines[i].domNode.innerHTML}</blockquote>`;
        // }
        if(lines[i].domNode.tagName == 'H1' || lines[i].domNode.tagName == 'H2' || lines[i].domNode.tagName == 'H3' || lines[i].domNode.tagName == 'H4' || lines[i].domNode.tagName == 'H5' || lines[i].domNode.tagName == 'PRE'){
          lines[i].domNode.outerHTML = `<p>${lines[i].domNode.innerHTML}</p>`;
          return;
        }
        for(var j in lines[i].domNode.childNodes){
          if(lines[i].domNode.childNodes[j].tagName == 'H1' || lines[i].domNode.childNodes[j].tagName == 'H2' || lines[i].domNode.childNodes[j].tagName == 'H3' || lines[i].domNode.childNodes[j].tagName == 'H4' || lines[i].domNode.childNodes[j].tagName == 'H5' || lines[i].domNode.childNodes[j].tagName == 'PRE'){
            lines[i].domNode.childNodes[j].outerHTML = `<p>${lines[i].domNode.childNodes[j].innerHTML}</p>`;
            return;
          }
          // if(lines[i].domNode.childNodes[j].style && lines[i].domNode.childNodes[j].style.fontSize){
          //   console.log(lines[i].domNode.childNodes[j].style.fontSize)
          // }
          // if(lines[i].domNode.childNodes[j].childNodes){
          //   for(const child of lines[i].domNode.childNodes[j].childNodes){
          //     if(child.style && child.style.fontSize){
          //       console.log(child.style.fontSize)
          //     }
          //   }
          // }
        }
        // console.log('line', i, lines[i])
        // console.log('line', i, lines[i], lines[(parseInt(i)+1)], lines[i].length())
        var _imgIdx = -1;
        for(j in lines[i].domNode.childNodes){
          if(lines[i].domNode.childNodes[j].tagName == 'IMG'){
            _imgIdx = j;
            break;
          }else if(lines[i].domNode.childNodes[j].childNodes){
            for(const child of lines[i].domNode.childNodes[j].childNodes){
              if(child.tagName == 'IMG'){
                // console.log('asdf')
                lines[i].domNode.childNodes[j].outerHTML = child.outerHTML;
                return;
              }
            }
          }
        }
        if(_imgIdx > -1){
          if(captionFlg){
            lines[i].domNode.outerHTML = '<p>ⓒ</p>' + lines[i].domNode.outerHTML;
            event.quill.setSelection(event.quill.getSelection() + 2)
            return
          }
          lines[i].domNode.style.color = '';
          lines[i].domNode.style.textAlign = "left";
          lines[i].domNode.style.marginBottom = "";
          if(this.initImage && this.sendData.images.length){
            if(this.sendData.images[imgIdx].align == 'C') {
              lines[i].domNode.childNodes[_imgIdx].style.display = 'block';
              lines[i].domNode.childNodes[_imgIdx].style.margin = '0 auto';
            }else if(this.sendData.images[imgIdx].align == 'L') {
              lines[i].domNode.childNodes[_imgIdx].style.float = 'left';
              lines[i].domNode.childNodes[_imgIdx].style.display = 'inline';
              lines[i].domNode.childNodes[_imgIdx].style.margin = '0px 1em 1em 0px';
            }else if(this.sendData.images[imgIdx].align == 'R') {
              lines[i].domNode.childNodes[_imgIdx].style.float = 'right';
              lines[i].domNode.childNodes[_imgIdx].style.display = 'inline';
              lines[i].domNode.childNodes[_imgIdx].style.margin = '0px 1em 1em 0px';
            }
            imgIdx++;
          }
          if(!lines[i].domNode.childNodes[_imgIdx].style.float) {
            // console.log('test')
            lines[i].domNode.childNodes[_imgIdx].style.display = 'block';
            lines[i].domNode.childNodes[_imgIdx].style.margin = '0 auto';
          }
          if(_imgIdx > 0){
            var _tmpIdx = lines[i].domNode.outerHTML.indexOf('<img src');
            lines[i].domNode.outerHTML = lines[i].domNode.outerHTML.substring(0,_tmpIdx) + '</p><p>' + lines[i].domNode.outerHTML.substring(_tmpIdx);
            // console.log('inner image', 1, _imgIdx)
            return
          }else if(lines[i].domNode.childNodes.length > 1) {
            _tmpIdx = lines[i].domNode.outerHTML.indexOf(lines[i].domNode.childNodes[_imgIdx].outerHTML) + lines[i].domNode.childNodes[_imgIdx].outerHTML.length;
            lines[i].domNode.outerHTML = lines[i].domNode.outerHTML.substring(0,_tmpIdx) + '</p><p>' + lines[i].domNode.outerHTML.substring(_tmpIdx);
            return
          }
          else {
            if(i == lines.length - 1){
              lines[i].domNode.outerHTML += 'ⓒ</p>'
              return
            }
            captionFlg = true;
          }
        }
        else if(captionFlg){
          captionFlg = false;
          _imgIdx = -1;
          for(j in lines[i].domNode.childNodes){
            if(lines[i].domNode.childNodes[j].tagName == 'IMG'){
              _imgIdx = j;
              break;
            }
          }
          if(_imgIdx == -1){
            lines[i].domNode.style.color = "rgb(219, 41, 41)";
            lines[i].domNode.style.textAlign = "center";
            lines[i].domNode.style.marginBottom = "30px";
            // console.log(lines[i].domNode)
            if(lines[i].domNode.textContent == ''){ 
              lines[i].domNode.outerHTML = '<p>ⓒ</p>'
              event.quill.setSelection(event.quill.getSelection())
              return
            }
          }
        }else if(lines[i].domNode.style.color == "rgb(219, 41, 41)"){
          lines[i].domNode.style.color = '';
          lines[i].domNode.style.textAlign = "left";
          lines[i].domNode.style.marginBottom = "";
        }
      }
      this.initImage = false;
      this.content = this.content.replace('<p>&lt;iframe ', '<iframe ').replace('&gt;&lt;/iframe&gt;</p>', '></iframe>').replace('?autoPlay=true', '?autoPlay=false');
      var strValue = this.content.replace(/<img[^>]*src=["']?([^>"']+)["']?[^>]*>(<\/p><p[^>]*>)?(.*?)<\/p>/g,"").replace(/<\/?([a-z]+[^>]*)>/g, "");//event.quill.getText().replace(/\n/g,'');
      var strLen = strValue.length; //글자수 셈하기
      this.textcount = strLen;
      this.papers = parseInt((strLen)/200);
    },
    captionImageCheck1(tmp2, tmpContent, i){
      if(tmp2 && tmp2[4].includes('<img ')){
        var tmp = tmpContent.match(/<img[^>]*src=["']?([^>"']+)["']?[^>]*>/);
        // console.log('tmp2 img:', tmp);
        var tmpContent1 = tmpContent.replace(tmp[0], '');
        tmp = tmpContent1.match(/<img[^>]*src=["']?([^>"']+)["']?[^>]*>(<\/span>)?(<\/p><p[^>]*>)?(.*?)<\/p>/);
        i++;
        if(!tmp){
          console.log('return');
          return [tmp2, tmpContent, i]
        }
        [tmp, tmpContent1, i] = this.captionImageCheck1(tmp, tmpContent1, i)
        return [tmp, tmpContent1, i]
      }else return [tmp2, tmpContent, i]
    },
    decodeHtmlChar(text){
      return text.replace(/&amp;|&quot;|&lt;|&gt;|&copy;|&deg;/g, ' ')
    },
    customButtonClick(editor, text) {
      // console.log(editor)
      editor.focus();
      editor.insertText(editor.getSelection().index, text)
    },
    changeEmail(){
      let tmpWriter = this.writer.find(data => data.idx == this.sendData.mno1);
      this.sendData.byline = 'EBN ' + tmpWriter.name+ ' ' + tmpWriter.tail+ ' (' + this.sendData.email + ')';
      for(var i=0;i<this.tmpWriter2.length;i++){
        this.sendData.byline += ', ' + this.tmpWriter2[i].name+ ' ' + this.tmpWriter2[i].tail
      }
    },
    setWriter(val){
      console.log(val)
      if(val.idx == 296){
        this.portalFlg = false;
      }
      this.sendData.mno1 = val.idx;
      this.sendData.name = val.name;
      this.sendData.tailname = val.tail;
      // document.querySelector('.ql-customMacro').value = this.sendData.byline;
      if(val.email) this.sendData.email = val.email;
      else this.sendData.email = '';
      this.sendData.byline = 'EBN ' + val.name+ ' ' + val.tail + ' (' + this.sendData.email + ')';
    },
    setWriter2(val){
      // console.log(val)
      if(val.length > 10){
        alert('최대 10명까지 입력 가능합니다.')
        this.tmpWriter2.splice(10);
      }
      // console.log('tmpWriter2: ',this.tmpWriter2)
      let tmpWriter = this.writer.find(data => data.idx == this.sendData.mno1);
      if(tmpWriter) this.sendData.byline = 'EBN ' + tmpWriter.name+ ' ' + tmpWriter.tail+ ' (' + this.sendData.email + ')';
      this.sendData.another_writer = '';
      for(var i=0;i<this.tmpWriter2.length;i++){
        if(this.tmpWriter2[i]) {
          this.sendData.another_writer += this.tmpWriter2[i].idx + ';';
          this.sendData.byline += ', ' + this.tmpWriter2[i].name+ ' ' + this.tmpWriter2[i].tail
        }
      }
      // document.querySelector('.ql-customMacro').value = this.sendData.byline;
      console.log('another_writer: ',this.sendData.another_writer)
    },
    setCategory1(val){
      if(val && val.code == 'PHOTO'){
        this.sendData.type = '9';
      }else{
        this.sendData.type = '1';
      }
      this.sendData.category2 = null;
      this.categories2_view = [];
      this.siteDisable = true;

      if(val && val.code == 'CHEM'){
        //this.familySite = ["2"];
        this.categories2_view = this.$store.state.categories2.filter(e => e.codeL == 'CHEM');
      }else if(val && val.code == 'BADA'){
        //this.familySite = ["3"];
        this.categories2_view = this.$store.state.categories2.filter(e => e.codeL == 'BADA');
      }else if(val && val.code == 'STEEL'){
        //this.familySite = ["4"];
        this.categories2_view = this.$store.state.categories2.filter(e => e.codeL == 'STEEL');
      }else if(val && val.code == 'OP'){
        this.categories2_view = this.$store.state.categories2.filter(e => e.codeL == 'OP');
      }
      if(this.categories2_view && this.categories2_view.length) this.category2Disable = false;
      else this.category2Disable = true;
      // if(val && (this.sendData.category2 || this.category2Disable)){
      // if(val){
      //   this.sendButtonDisable = false;
      // }else this.sendButtonDisable = true;
      this.sendData.site = false;
      this.seriesArray = this.$store.state.series
    },
    setCategory2(val){
      console.log(val)
      // if(val.length > 5){
      //   alert('최대 5개까지 입력 가능합니다.')
      //   this.sendData.category2.splice(5);

      //   this.form.data.category2.toString = ``

      //   console.log('this.sendData.category2::', this.sendData.category2)
      //   console.log('this.sendData.category2[0].name:', this.sendData.category2[0].name)

      //   for(var idx in this.sendData.category2) {

      //     if(this.form.data.category2.toString == ``) {

      //       this.form.data.category2.toString = this.sendData.category2[idx].name
      //     }
      //     else {
      //       this.form.data.category2.toString += `, ${this.sendData.category2[idx].name}`
      //     }
      //   }

      //   var trueCount = 0
      //   for(idx in this.form.data.category2.categories) {

      //     if(this.form.data.category2.categories[idx]) {
            
      //       trueCount++
      //     }

      //     if(trueCount > 5) {
            
      //       for(var i = idx; i < this.form.data.category2.categories.length; i++) {

      //         this.form.data.category2.categories[i] = false
      //       }

      //       break
      //     }
      //    }
      // }
      // if(this.sendData.category2.findIndex(e => e.code == 509 || e.code == 608) > -1){
      //   this.reviewDisable = false;
      // }else{
      //   this.sendData.review_rate = {'rate': ''};
      //   this.reviewDisable = true;
      // }
    },
    setNewCategory1(val){
      if(val && val.code == 12){
        this.sendData.type = '9';
      }else{
        this.sendData.type = '1';
      }
      this.sendData.newCategory2 = null;
      this.newCategories2View = [];
      if(val){
        this.newCategories2View = this.newCategories2.filter(e => e.codeL == val.code);
      }
      if(this.newCategories2View && this.newCategories2View.length) this.newCategory2Disable = false;
      if(val){
        this.sendButtonDisable = false;
      }else this.sendButtonDisable = true;
    },
    setNewCategory2(val){
      console.log(val)
    },
    setSeries(e){
      if(e.code == 177){
        this.portalFlg = false;
      }
    },
    photoCheck(e){
      console.log('photo check', e, this.sendData.type)
      if(e == 'on'){
        this.sendData.category1 = this.categories1.find(e => e.code == 'PHOTO');
        this.setCategory1(this.sendData.category1);
      }else{
        this.sendData.category1 = null;
        this.setCategory1(this.sendData.category1);
        setTimeout(() => {
          const customSpecialPickerItems3 = Array.prototype.slice.call(document.querySelectorAll('#toolbar3 .ql-customSpecial .ql-picker-item'));
          customSpecialPickerItems3.forEach(item => item.textContent = item.dataset.value);
          document.querySelector('#toolbar3 .ql-customSpecial .ql-picker-label').innerHTML
              = '특수문자' + document.querySelector('#toolbar3 .ql-customSpecial .ql-picker-label').innerHTML;
        }, 100)
      }
    },
    watermarkToggle(e){
      // console.log(e)
      if(e.value) { this.sendData.watermark = this.activeUserInfo.watermark }
      else { this.sendData.watermark = ''; }
    },
    addFile(e){
      console.log(e, e.target.files);
      this.submitFiles(e.target.files);
    },
    submitFiles(files){
      let formData = new FormData();
      let thisIns = this;

      for( var i = 0; i < files.length; i++ ){
        let file = files[i];
        formData.append('file', file);
      }
      formData.append('newsId', this.sendData.id)
      this.$http.post( '/api/writeArticles/thumbnailUpload',
        formData,
        {
          headers: {
              'Content-Type': 'multipart/form-data'
          },
          // onUploadProgress: function( progressEvent ) {
          //   this.uploadPercentage = parseInt( Math.round( ( progressEvent.loaded * 100 ) / progressEvent.total ) );
          // }.bind(this)
        }
      ).then(function(response){
        console.log('SUCCESS!!', response);
        thisIns.sendData.icon = response.data;
      })
      .catch(function(response){
        console.log('FAILURE!!', response);
      });
    },
    created() {
      
      // const root = "https://cdnimage.dailian.co.kr/news/";
      // console.log(this.$store.state.states, this.$store.state.maxWidth)
      this.sendData.mainTitle = "";
      this.sendData.subTitle = "";
      this.sendData.state = this.$store.state.states.find(e => e.code == 9);
      this.sendData.news_kind = {name: '뉴스', code: '1'};
      this.pastState = "9";
      this.sendData.site = false;
      this.states = this.$store.state.states.slice(0,2);
      this.sendData.type = '1';
      this.sendData.category1 = [];
      this.sendData.category2 = [];
      this.sendData.newCategory1 = [];
      this.sendData.newCategory2 = [];
      this.sendData.review_rate = {'rate': ''};
      this.sendData.series = null;
      this.sendData.images = [];
      this.sendData.icon = '';
      this.sendData.tag = [];
      this.content = "";
      this.sendData.out_site = "";
      this.sendData.mno1 = this.$store.state.AppActiveUser.id;
      this.tmpWriter = this.writer.find(data => data.idx == this.sendData.mno1);
      this.sendData.writer = this.$store.state.AppActiveUser.id;
      this.sendData.another_writer = '';
      this.tmpWriter2 = [];
      this.sendData.name = this.$store.state.AppActiveUser.name;
      this.sendData.tailname = this.$store.state.AppActiveUser.tail;
      if(this.$store.state.AppActiveUser.email) { this.sendData.email = this.$store.state.AppActiveUser.email; }
      else {this.sendData.email = '';}
      this.sendData.byline = 'EBN ' + this.$store.state.AppActiveUser.writer
                              + ' ' + this.$store.state.AppActiveUser.tail + ' (' + this.sendData.email + ')';
      this.sendData.d_pass = new Date();
      this.news_rel = "";
      // console.log('query id:' + this.$route.query.id, this.sendData.writer);
      const thisIns = this;
      this.$vs.loading({
        scale: 1.5
      });
      if(this.$route.query.id){
        this.sendData.id = this.$route.query.id;
            thisIns.$http.post('/api/getSingleNews', thisIns.$route.query)
              .then(function (response) {
                
                if(response.data.deadDocument) {
                  return thisIns.$router.back();
                }

                if(thisIns.sendData.mno1 != response.data[0][0].mno1 && thisIns.sendData.mno1 != response.data[0][0].first_mno && thisIns.$store.state.AppActiveUser.level < 8){
                  alert('작성자가 아닙니다!');
                  thisIns.autoSaveFlg = false;
                  thisIns.lockFlg = false;
                  thisIns.$router.back();
                }else if(response.data[0][0].state == 1 && thisIns.$store.state.AppActiveUser.level < 8){
                  alert('송고된 기사는 수정할 수 없습니다!');
                  thisIns.autoSaveFlg = false;
                  thisIns.lockFlg = false;
                  thisIns.$router.back();
                }
                if(response.data[0][0].title == '기사 작성중'){
                  thisIns.sendData.mainTitle = '';
                  thisIns.delFlg = true;
                }else{
                  thisIns.sendData.mainTitle = response.data[0][0].title.replace(/<\/?(h[^>]*|p[^>]*|div[^>]*)>/g, "");
                }
                thisIns.sendData.subTitle = response.data[0][0].sub_title.replace(/<\/?(h[^>]*|span[^>]*|div[^>]*|strong[^>]*)>/g, "");
                thisIns.sendData.mno1 = response.data[0][0].mno1;
                thisIns.tmpWriter = thisIns.writer.find(data => data.idx == thisIns.sendData.mno1);
                if(thisIns.tmpWriter) {
                  thisIns.setWriter(thisIns.tmpWriter);
                  thisIns.sendData.name = thisIns.tmpWriter.nickname;
                  thisIns.sendData.tailname = thisIns.tmpWriter.tail;
                }else {
                  thisIns.sendData.name = response.data[0][0].name;
                }
                // console.log(thisIns.sendData.name)
                // thisIns.sendData.writer = response.data[0][0].mno1;
                thisIns.sendData.another_writer = response.data[0][0].another_writer;
                // console.log(thisIns.sendData.another_writer.split(';'));
                if(thisIns.sendData.another_writer){
                  thisIns.sendData.another_writer.split(';').forEach(data =>{
                    if(data)
                    thisIns.tmpWriter2.push(thisIns.writer.find(_data => _data.idx == data))
                  });
                }
                thisIns.setWriter2(thisIns.tmpWriter2);
                if(response.data[0][0].email){
                  thisIns.sendData.email = response.data[0][0].email;
                }else {
                  thisIns.sendData.email = '';
                }

                thisIns.sendData.state = thisIns.$store.state.states.find(e => e.code == response.data[0][0].state);
                thisIns.pastState = response.data[0][0].state;
                if(response.data[0][0].news_kind == 2){
                  thisIns.sendData.news_kind = {name: '칼럼', code: '2'};
                }

                if(thisIns.sendData.state.code > 8) thisIns.states = thisIns.$store.state.states.slice(0,2);
                if(thisIns.sendData.state.code == 1) thisIns.states = thisIns.$store.state.states.filter(e => e.code == 1 || e.code == 0);
                else thisIns.states = thisIns.$store.state.states;
                if(response.data[0][0].site == 'Y') thisIns.sendData.site = true;
                else thisIns.sendData.site = false;
                if(response.data[3].length){
                  thisIns.sendData.rel = response.data[3];
                  thisIns.news_rel = "<h3>관련기사</h3><ul>"
                  for(let i=0; i < response.data[3].length; i++){
                    thisIns.news_rel += "<li>" + response.data[3][i].title + "</li>"
                  }
                  thisIns.news_rel += "</ul>"

                }
                thisIns.sendData.category1 = thisIns.$store.state.categories1.find(e => e.code == response.data[0][0].cate_code);
                thisIns.setCategory1(thisIns.sendData.category1);
                console.log("category1: ", thisIns.sendData.category1)
                thisIns.sendData.category2 = thisIns.$store.state.categories2.find(e => e.code == response.data[0][0].cate_code2);
                

                thisIns.sendData.newCategory1 = thisIns.newCategories1.find(e => e.code == response.data[0][0].category1);
                thisIns.setNewCategory1(thisIns.sendData.newCategory1);
                console.log("new category1: ", thisIns.sendData.newCategory1)
                thisIns.sendData.newCategory2 = thisIns.newCategories2.find(e => e.code == response.data[0][0].category2);

                thisIns.sendData.series = thisIns.$store.state.series.find(e => e.code == response.data[0][0].menu_code);

                thisIns.sendData.out_site = response.data[0][0].out_site;
                if(thisIns.sendData.state.code < 3 || moment(response.data[0][0].d_pass) > moment())
                  thisIns.sendData.d_pass = new Date(moment(response.data[0][0].d_pass));
                thisIns.sendData.images = response.data[1];
                for(let i in thisIns.sendData.images){
                  if(thisIns.sendData.images[i].pno){
                    thisIns.photoData.push({'img': thisIns.sendData.images[i].filename, 'pno': thisIns.sendData.images[i].pno});
                  }
                }
                thisIns.sendData.icon = response.data[0][0].icon;
                let tags = response.data[4];
                for(let i=0;i<tags.length;i++){
                  thisIns.sendData.tag.push(tags[i].tag);
                }
                // console.log('asdfasdfasdfasdfasdf: ', response.data[0][0].familySiteSend)
                if(response.data[0][0].familySiteSend){
                  thisIns.familySite = response.data[0][0].familySiteSend.split('^').filter(e => e);
                }
                thisIns.sendData.fastnews = response.data[0][0].fastnews || '1';
                thisIns.sendData.news_free = response.data[0][0].news_free || '1';
                if(thisIns.sendData.news_free == 3){ thisIns.newsfreeFlg = true; }
                thisIns.sendData.out_state = response.data[0][0].out_state || '0';
                if(thisIns.sendData.out_state == '1'){ thisIns.portalFlg = true; }
                thisIns.lockHistory = '';
                let logs = response.data[2];
                logs.forEach(log => {
                  thisIns.lockHistory += log.name + ': ' + log.state + ' at ' + log.access_date + ' \n';
                })
                if(logs[0] && logs[0].state == 'lock' && moment(logs[0].access_date) > moment().subtract(1, 'hour') && logs[0].reporter_idx != thisIns.$store.state.AppActiveUser.id ){
                  console.log('lock: ', logs[0].reporter_idx, thisIns.$store.state.AppActiveUser.id)
                  alert('잠겨있는 기사입니다. 수정할 수 없습니다. ' + parseInt((moment(logs[0].access_date) - moment().subtract(1, 'hour')) / 1000 / 60  + 1) + '분 남음' )
                  thisIns.autoSaveFlg = false;
                  thisIns.lockFlg = false;
                  thisIns.previewDisable = true;
                }else{
                  thisIns.previewDisable = false;
                  thisIns.$http.post('/api/writeArticles/lock', {id: thisIns.sendData.id, name: thisIns.$store.state.AppActiveUser.name, mno1: thisIns.$store.state.AppActiveUser.id, lock: 'lock'})
                    .then(function (response) {
                      console.log(response);
                      thisIns.lockHistory = thisIns.$store.state.AppActiveUser.name + ': lock at ' + moment().format('YYYY-MM-DD HH:mm:ss') + ' \n' + thisIns.lockHistory;
                    })
                    .catch(function (error) {
                      console.log(error);
                      thisIns.$vs.notify({
                        title:'Error',
                        text: error,
                        color:'danger',
                        iconPack: 'feather',
                        icon:'icon-alert-circle'})
                      thisIns.$vs.loading.close();  
                    });
                }
                thisIns.content = thisIns.loadData(response.data[0][0].contents);
                console.log("content: ",response.data[0][0].contents, thisIns.content)
                thisIns.sendButtonDisable = true;
                if(response.data[0][0].byline) { thisIns.sendData.byline = response.data[0][0].byline }
                thisIns.$vs.loading.close();  
              })
              .catch(function (error) {
                console.log(error);
                thisIns.$vs.notify({
                  title:'Error',
                  text: error,
                  color:'danger',
                  iconPack: 'feather',
                  icon:'icon-alert-circle'})
                thisIns.$vs.loading.close();  
              });
      }
      else {
        this.sendData.mainTitle = "";
        this.sendData.text = "";
        this.sendData.id = '';
        const thisIns = this;
          // console.log(this.sendData)
        this.$http.post('/api/writeArticles', this.sendData)
          .then(function (response) {
            console.log(response)
            thisIns.autoSaveFlg = false;
            if(thisIns.$route.query.pno) thisIns.$router.push({path: '/writeArticles', query: {id: response.data.id, pno: thisIns.$route.query.pno}});
            else thisIns.$router.push({path: '/writeArticles', query: {id: response.data.id}});
            thisIns.$vs.loading.close();  
          })
          .catch(function (error) {
            thisIns.$vs.notify({
              title:'Error',
              text: error,
              color:'danger',
              iconPack: 'feather',
              icon:'icon-alert-circle'})
            thisIns.$vs.loading.close();  
          });
      }
    },
    showSidebar() {
        this.$store.commit('TOGGLE_IS_SIDEBAR_ACTIVE', true);
    },
  },
  computed: {
    activeUserInfo() {
        return this.$store.state.AppActiveUser;
    },
  },
  created() {
    // console.log(this.sendData);
    this.categories1 = this.$store.state.categories1;
    this.categories2 = this.$store.state.categories2;
    this.newCategories1 = this.$store.state.newCategories1;
    this.newCategories2 = this.$store.state.newCategories2;
    this.types = this.$store.state.types;
    this.writer = this.$store.state.writer;
    this.portals = this.$store.state.portals;
    this.seriesArray = this.$store.state.series;
    this.created();
  },
  mounted(){
    const customSpecialPickerItems = Array.prototype.slice.call(document.querySelectorAll('#toolbar .ql-customSpecial .ql-picker-item'));
    customSpecialPickerItems.forEach(item => item.textContent = item.dataset.value);
    document.querySelector('#toolbar .ql-customSpecial .ql-picker-label').innerHTML
        = '특수문자' + document.querySelector('#toolbar .ql-customSpecial .ql-picker-label').innerHTML;
    const customSpecialPickerItems2 = Array.prototype.slice.call(document.querySelectorAll('#toolbar2 .ql-customSpecial .ql-picker-item'));
    customSpecialPickerItems2.forEach(item => item.textContent = item.dataset.value);
    document.querySelector('#toolbar2 .ql-customSpecial .ql-picker-label').innerHTML
        = '특수문자' + document.querySelector('#toolbar2 .ql-customSpecial .ql-picker-label').innerHTML;
    const customSpecialPickerItems3 = Array.prototype.slice.call(document.querySelectorAll('#toolbar3 .ql-customSpecial .ql-picker-item'));
    customSpecialPickerItems3.forEach(item => item.textContent = item.dataset.value);
    document.querySelector('#toolbar3 .ql-customSpecial .ql-picker-label').innerHTML
        = '특수문자' + document.querySelector('#toolbar3 .ql-customSpecial .ql-picker-label').innerHTML;
    document.querySelector('#toolbar .ql-blockquote').innerHTML
        = '<b style="font-size: 16px; line-height: 19px;">내부부제</b>';
    
    var thisObj = this

    ImageResize.setOnChangedListener(function(image, styleName) {
      
      console.log('listener:', image, styleName)
      thisObj.$refs.quillEditor_contents.quill.insertText(0, ' ')
      thisObj.$refs.quillEditor_contents.quill.deleteText(0, 1)
    })
    setTimeout(() => { this.timeFlg = true; }, 2000);
  },
}
  </script>

<style lang="scss">
@import "@/assets/scss/custom-style/writeArticles.scss";
</style>